<template>
  <VMenu
    min-width="250px"
    left
    offset-x
    nudge-left="24"
  >
    <template #activator="{ attrs, on }">
      <slot
        name="activator"
        :attrs="attrs"
        :on="on"
      />
    </template>
    <VList>
      <VListItem
        v-for="action in actions"
        :key="action.value"
        link
        @click.native="action.action"
      >
        <VListItemIcon class="mr-3">
          <VIcon
            :class="action.color"
            size="19"
            v-text="action.icon"
          />
        </VListItemIcon>
        <VListItemContent :class="['tt-text-body-2 py-4', action.color]">
          <span v-html="action.title" />
        </VListItemContent>
      </VListItem>
    </VList>
  </VMenu>
</template>
<script>
export default {
  name: 'ActionMenu',
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.v-menu {
  &__content {
    box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08);
  }
}
.v-list {
  padding: 0;
  min-width: 250px;
}
.v-list-item {
  &:not(:last-child) {
    .v-list-item__content {
      border-bottom: 1px solid map-deep-get($tt-light-mono-8, 'base');
    }
  }
}
</style>
